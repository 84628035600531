import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - CVRE"
  const activePageText = "Case Study - CVRE"

  // Main Content Area
  const type =
    "1 Admin Website + 1 Customer Facing Website + 1 Backend Database + 1 Cloud Functions";

const title = "An full and evolving pre-construction management and sales suite."
const body1 = "The Challenge - CVRE’s challenge was to combine the core functionality of multiple apps into a single app with a limited budget. This included listing management, broker commissions, staff & sales management, builder info, notification & blasting abilities, and different levels of info for different users. Data must be syndicated across all 3 Admin, Website and Apps. This first version was build with React, Firebase, Cloud Functions and is able to significantly save cost."
const body2 = "The Solution - We built a full suite product with all the features. A creative 3D modelling idea was built but later removed in the final version because the content required to feed the feature was deemed too expensive to generate. Project time-line was delayed 2 months due to added features in-mid project. Expected release is end of Feb 2023. "
const body3 = "After 10 Months of development, CVRE was able to fully migrate over to the application & cut a lot of extremely expensive subscriptions."
const results = "The Result - CVRE was able to use the project to showcase investment in a sector and gain favour. In addition save tremendous software subscription costs… plus own a product of their own!"


  // Side bar content area
  const clientName = "CVRE"
  const clientLink = "https://condovillegroup.com"
  const clientName2 = ""
  const clientLink2 = ""
  const location = "Etobicoke, ON, Canada"
  const technologies = "Front-end: React Native, React, Next   Back-end:"
  const completionDate = "2023 Jan"
  const products = [{ hrefName: "CVRE - Driver Application" },
  { hrefName: "CVRE - Sales Portal", href: 'https://market.cvre.ca' },
  { hrefName: "CVRE - Admin Portal" },
]

  return (
    <Layout pageTitle={pageTitle}>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        products={products}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
